<template>
  <v-overlay z-index="9999" color="neutral0" opacity="1">
    <v-container fill-height>
      <v-row align="center" justify="center">
        <template v-if="userId">
          <v-col cols="12" class="text-center">
            <BrandButton
              large variant="primary" min-width="220"
              @click="onBookmark"
            >
              {{ $t('cta.bookmark_this_page') }}
            </BrandButton>
          </v-col>
          <v-col cols="12" class="text-center">
            <BrandButton
              large variant="secondary" min-width="220"
              target="_blank" :href="extAppUrl"
            >
              {{ $t('cta.open_rubra') }}
            </BrandButton>
          </v-col>
        </template>
        <template v-else>
          <!-- Removed due to Apple policies -->
          <!--
          <v-col cols="12" class="text-center">
            <BrandButton
              large variant="secondary" min-width="220"
              target="_blank"
              :href="extAppUrl"
            >
              {{ $t('cta.social_login') }}
            </BrandButton>
          </v-col>
          -->
          <v-col cols="12" class="text-center">
            <BrandButton
              large variant="primary" min-width="220"
              :href="extAppUrl"
            >
              {{ $t('cta.sign_up') }}
            </BrandButton>
          </v-col>
          <v-col cols="12" class="text-center">
            <BrandButton
              large variant="secondary" min-width="220"
              :href="extAppUrl"
            >
              {{ $t('cta.login') }}
            </BrandButton>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </v-overlay>
</template>

<script>
import { mapGetters } from 'vuex'

let browser = null
if (BUILD_TARGET === 'extension') {
  // eslint-disable-next-line global-require
  browser = require('webextension-polyfill')
}

export default {
  data() {
    return {
      extAppUrl: browser.runtime.getURL('index.html'),
    }
  },
  computed: {
    ...mapGetters({
      userId: 'auth/userId',
      isMobile: 'device/isMobile',
      isNative: 'device/isNative',
      isExtension: 'device/isExtension',
      isExtensionPopup: 'device/isExtensionPopup',
      isExtensionMini: 'device/isExtensionMini',
    }),
  },
  methods: {
    onBookmark() {
      browser.runtime.sendMessage({ event: 'background:bookmark-page' })
      window.close()
    },
  },
}
</script>
