<template>
  <article class="bookmark-item">
    <component
      :is="comp"
      :bookmark="bookmark" :user-id="userId"
      v-on="bookmarkActionHandler"
    />

    <!-- HACK FIXME move these to parent, no need to dupe, this slows down performance a lot -->
    <BookmarkOptions
      v-if="dirty"
      v-model="optionsOpen"
      :activator="$el"
      :bookmark="bookmark"
      :user-id="userId"
      :with-secondary-action="false"
      :with-primary-action="false"
      :is-grid-layout="isGridLayout"
      v-on="bookmarkActionHandler"
    />

    <BookmarkTagsFilter
      v-if="dirty"
      v-model="tagsFilterOpen"
      :activator="$el" :bookmark="bookmark" :user-id="userId"
      @submit="$emit('filter-tags', $event)"
      @edit="$emit('action', { action: 'tags', event: $event })"
    />
  </article>
</template>

<script>
import Bookmark from '@/store/models/bookmarks/Bookmark'
import { ITEM_LAYOUT_STYLE } from '@/utils/constants'
import BookmarkCard from './BookmarkCard.vue'
import BookmarkListItem from './BookmarkListItem.vue'
import BookmarkHeadline from './BookmarkHeadline.vue'
import BookmarkTile from './BookmarkTile.vue'

const STYLES = {
  [ITEM_LAYOUT_STYLE.cards]: BookmarkCard,
  [ITEM_LAYOUT_STYLE.list]: BookmarkListItem,
  [ITEM_LAYOUT_STYLE.headlines]: BookmarkHeadline,
  [ITEM_LAYOUT_STYLE.tiles]: BookmarkTile,
}

export default {
  components: {
    BookmarkOptions: () => import('./BookmarkOptions.vue'),
    BookmarkTagsFilter: () => import('./BookmarkTagsFilter.vue'),
  },
  props: {
    bookmark: {
      type: Bookmark,
      required: true,
    },
    userId: {
      type: String,
      default: null,
    },
    listStyle: {
      type: String,
      default: 'card',
    },
  },
  data() {
    return {
      dirty: false,
      tagsFilterOpen: false,
      optionsOpen: false,
    }
  },
  computed: {
    comp() {
      return STYLES[this.listStyle]
    },
    isGridLayout() {
      return [ITEM_LAYOUT_STYLE.cards, ITEM_LAYOUT_STYLE.tiles].includes(this.listStyle) && this.$vuetify.breakpoint.smAndUp
    },
    bookmarkActionHandler() {
      return {
        favorite: event => this.$emit('action', { action: 'favorite', event }),
        save: event => this.$emit('action', { action: 'save', event }),
        comments: event => this.$emit('action', { action: 'comments', event }),
        share: event => this.$emit('action', { action: 'share', event }),
        tags: event => this.$emit('action', { action: 'tags', event }),
        edit: event => this.$emit('action', { action: 'edit', event }),
        'add-to-collection': event => this.$emit('action', { action: 'add-to-collection', event }),
        delete: event => this.$emit('action', { action: 'delete', event }),
        'tag-selected': this.onSelectTag,
        more: this.onMoreOptions,
        'toggle-select': event => this.$emit('action', { action: 'toggle-select', event }),
        like: event => this.$emit('action', { action: 'like', event }),
        likes: event => this.$emit('action', { action: 'likes', event }),
      }
    },
  },
  methods: {
    onSelectTag() {
      this.dirty = true
      this.tagsFilterOpen = true
    },
    onMoreOptions() {
      this.dirty = true
      this.optionsOpen = true
    },
  },
}
</script>
