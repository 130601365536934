<template>
  <div :key="bookmark.id" class="bookmark-actions d-flex">
    <v-tooltip
      v-if="!bookmark.isCollectionBookmark"
      top open-delay="300" color="neutral900 neutral0--text"
    >
      <template #activator="{ on }">
        <BrandButton
          small color="neutral900" icon
          :loading="bookmark.loading.favorite"
          v-on="on"
          @click.stop.prevent="$emit('favorite')"
        >
          <PhStar class="text-h5" :weight="bookmark.isFavorite ? 'fill' : 'regular'" />
        </BrandButton>
      </template>
      <span v-text="$t('cta.favorite')" />
    </v-tooltip>

    <template v-if="!hideSecondaryAction">
      <v-tooltip top open-delay="300" color="neutral900 neutral0--text">
        <template #activator="{ on }">
          <BrandButton
            v-if="bookmark.isCollectionBookmark" small
            color="neutral900" icon v-on="on" @click.stop.prevent="$emit('save')"
          >
            <PhPlus class="text-h5" />
          </BrandButton>
          <BrandButton
            v-else small
            color="neutral900" icon v-on="on" @click.stop.prevent="$emit('add-to-collection')"
          >
            <PhListPlus class="text-h5" />
          </BrandButton>
        </template>
        <span v-text="$t(bookmark.isCollectionBookmark ? 'cta.add_to_bookmarks' : 'cta.add_to_collection')" />
      </v-tooltip>
    </template>

    <template v-if="!hidePrimaryAction">
      <v-tooltip top open-delay="300" color="neutral900 neutral0--text">
        <template #activator="{ on }">
          <v-badge
            bordered overlap dot color="red400"
            :value="bookmark.hasUnreadComments"
            offset-y="12"
          >
            <BrandButton
              small color="neutral900" icon @click.stop.prevent="$emit('comments')"
              v-on="on"
            >
              <PhChatCenteredText class="text-h5" />
            </BrandButton>
          </v-badge>
        </template>
        <span v-text="$tc(bookmark.isCollectionBookmark ? 'common.comments' : 'common.notes', 2)" />
      </v-tooltip>
      <v-tooltip top open-delay="300" color="neutral900 neutral0--text">
        <template #activator="{ on }">
          <BrandButton
            class="ml-n5 px-0"
            :style="{minWidth: 'unset'}"
            :class="{
              // 'text-decoration-underline': bookmark.commentsCount > 0,
              // 'cursor-default': bookmark.commentsCount === 0
            }"
            plain :ripple="false"
            small color="neutral900" icon @click.stop.prevent="$emit('comments')"
            v-on="on"
          >
            {{ bookmark.commentsCount }}
          </BrandButton>
        </template>
        <span v-text="$tc(bookmark.isCollectionBookmark ? 'common.comments' : 'common.notes', 2)" />
      </v-tooltip>
    </template>

    <template v-if="!hideThirdAction">
      <v-tooltip top open-delay="300" color="neutral900 neutral0--text">
        <template #activator="{ on }">
          <BrandButton
            v-if="bookmark.isCollectionBookmark"
            class="ml-n2"
            :loading="bookmark.loading.like" small
            color="neutral900" icon v-on="on" @click.stop.prevent="$emit('like')"
          >
            <PhThumbsUp class="text-h5" :weight="bookmark.isLikedByUser ? 'fill' : 'regular'" />
          </BrandButton>
        </template>
        <span v-text="$t('cta.like')" />
      </v-tooltip>
      <v-tooltip top open-delay="300" color="neutral900 neutral0--text">
        <template #activator="{ on }">
          <BrandButton
            v-if="bookmark.isCollectionBookmark"
            :loading="bookmark.loading.like" small plain :ripple="false"
            class="ml-n3 px-1"
            :style="{minWidth: 'unset'}"
            :class="{'text-decoration-underline': bookmark.likes > 0, 'cursor-default': bookmark.likes === 0}"
            color="neutral900" v-on="on" @click.stop.prevent="bookmark.likes > 0 && $emit('likes')"
          >
            {{ bookmark.likes }}
          </BrandButton>
        </template>
        <span v-text="$t('common.likes')" />
      </v-tooltip>
    </template>

    <v-spacer />

    <BrandButton small color="neutral900" icon @click.stop.prevent="$emit('more')">
      <PhDotsThreeVertical class="text-h5" />
    </BrandButton>
  </div>
</template>

<script>
import {
  PhStar, PhChatCenteredText, PhPlus, PhListPlus, PhDotsThreeVertical, PhThumbsUp,
} from 'phosphor-vue'
import Bookmark from '@/store/models/bookmarks/Bookmark'

export default {
  components: {
    PhStar,
    PhPlus,
    PhListPlus,
    PhChatCenteredText,
    PhDotsThreeVertical,
    PhThumbsUp,
  },
  props: {
    bookmark: {
      type: Bookmark,
      required: true,
    },
    userId: {
      type: String,
      default: null,
    },
    hidePrimaryAction: Boolean,
    hideSecondaryAction: Boolean,
    hideThirdAction: Boolean,
  },
}
</script>

<style lang="scss" scoped>
.bookmark-actions {
  gap: 12px;
}
</style>
