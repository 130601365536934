<template>
  <div class="of-hidden">
    <template v-if="listStyle === STYLES.cards">
      <v-skeleton-loader v-bind="$attrs" type="image" class="mb-3" />
      <v-skeleton-loader v-bind="$attrs" type="sentences" max-width="320" class="mb-2" />
      <div class="d-flex">
        <v-skeleton-loader v-bind="$attrs" type="chip" height="24" class="mr-2" />
        <v-skeleton-loader
          v-show="$vuetify.breakpoint.smAndUp" v-bind="$attrs" type="chip" height="24"
          class="mr-2"
        />
        <v-skeleton-loader v-show="$vuetify.breakpoint.mdAndUp" v-bind="$attrs" type="chip" height="24" />
      </div>
    </template>
    <template v-if="listStyle === STYLES.tiles">
      <div class="d-flex align-start mb-2">
        <v-skeleton-loader
          v-bind="$attrs" type="image" class="flex-grow-1 mr-4" height="48"
          max-width="150"
        />
        <div class="flex-grow-1">
          <v-skeleton-loader v-bind="$attrs" type="sentences" max-width="320" />
        </div>
      </div>
      <div class="d-flex">
        <v-skeleton-loader v-bind="$attrs" type="chip" height="24" class="mr-2" />
        <v-skeleton-loader
          v-show="$vuetify.breakpoint.smAndUp" v-bind="$attrs" type="chip" height="24"
          class="mr-2"
        />
        <v-skeleton-loader v-show="$vuetify.breakpoint.mdAndUp" v-bind="$attrs" type="chip" height="24" />
      </div>
    </template>
    <template v-if="listStyle === STYLES.headlines">
      <v-skeleton-loader v-bind="$attrs" type="sentences" max-width="400" class="mb-2" />
      <div class="d-flex">
        <v-skeleton-loader v-bind="$attrs" type="chip" height="24" class="mr-2" />
        <v-skeleton-loader
          v-show="$vuetify.breakpoint.smAndUp" v-bind="$attrs" type="chip" height="24"
          class="mr-2"
        />
        <v-skeleton-loader v-show="$vuetify.breakpoint.mdAndUp" v-bind="$attrs" type="chip" height="24" />
      </div>
    </template>
    <template v-if="listStyle === STYLES.list">
      <div class="d-flex align-start">
        <v-skeleton-loader
          v-bind="$attrs" tile
          height="32"
          max-width="32"
          type="avatar"
          class="mr-4"
        />
        <div class="flex-grow-1">
          <v-skeleton-loader
            v-bind="$attrs" type="image" class="flex-grow-1 mb-2" height="12"
            max-width="100"
          />

          <v-skeleton-loader v-bind="$attrs" type="text" max-width="340" class="mb-2" />
          <div class="d-flex">
            <v-skeleton-loader v-bind="$attrs" type="chip" height="24" class="mr-2" />
            <v-skeleton-loader
              v-show="$vuetify.breakpoint.smAndUp" v-bind="$attrs" type="chip" height="24"
              class="mr-2"
            />
            <v-skeleton-loader v-show="$vuetify.breakpoint.mdAndUp" v-bind="$attrs" type="chip" height="24" />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { ITEM_LAYOUT_STYLE } from '@/utils/constants'

export default {
  inheritAttrs: true,
  props: {
    listStyle: {
      type: String,
      required: true,
    },
  },
  computed: {
    STYLES() {
      return ITEM_LAYOUT_STYLE
    },
  },
}
</script>

<style lang="scss" scoped>
.v-skeleton-loader {
  :deep(.v-skeleton-loader__chip) {
    height: 24px !important;
  }

  // :deep(.v-skeleton-loader__bone) {
  //   max-width: 100%;
  // }
}

</style>
