import Bookmark from '@/store/models/bookmarks/Bookmark'
import UserComment from '@/store/models/user/UserComment'
import UserLike from '@/store/models/user/UserLike'

export default {
  name: 'bookmark',
  namespaced: true,
  actions: {
    // UPDATE
    async edit(_, { bookmark, data }) {
      const result = await this.$api.bookmark.edit(bookmark.ownerId, bookmark.id, data)
      await Bookmark.update({ where: bookmark.id, data: result })
      return result
    },
    async editThumbnail(_, { bookmark, data }) {
      const { data: result } = await this.$api.bookmark.editThumbnail(bookmark.ownerId, bookmark.id, data, {
        params: { imageType: 'image' },
      })
      await Bookmark.update({ where: bookmark.id, data: { image: result } })
      return result
    },
    async editTags(_, { bookmark, data }) {
      const result = await this.$api.bookmark.editTags(bookmark.ownerId, bookmark.id, data)

      // NOTE we do not delete result.deletedTagIds from local store, as we still want to show them
      // inside things like the tag filter dropdown, even if they no longer exist in the collection

      // NOTE we don't update bookmark here because we need to refetch bookmark to get proper tag population

      return result
    },
    async toggleFavorite(_, bookmark) {
      try {
        await bookmark.setLoading('favorite', true)
        const result = await this.$api.bookmark.edit(bookmark.ownerId, bookmark.id, {
          isFavorite: !bookmark.isFavorite,
        })

        await Bookmark.update({ where: bookmark.id, data: result })

        return result
      } finally {
        await bookmark.setLoading('favorite', false)
      }
    },
    // DELETE
    async delete(_, bookmark) {
      try {
        await bookmark.setLoading('remove', true)
        await this.$api.bookmark.remove(bookmark.ownerId, bookmark.id)
        await Bookmark.delete(bookmark.id)

        return true
      } finally {
        await bookmark.setLoading('remove', false)
      }
    },
    async fullDelete(_, bookmark) {
      try {
        await bookmark.setLoading('remove', true)
        await this.$api.bookmark.removeFull(bookmark.ownerId, bookmark.id)
        await Bookmark.delete(bookmark.id)

        return true
      } finally {
        await bookmark.setLoading('remove', false)
      }
    },
    async restore(_, bookmark) {
      try {
        await bookmark.setLoading('restore', true)
        await this.$api.bookmark.restore(bookmark.ownerId, bookmark.id)
        await Bookmark.delete(bookmark.id)

        return true
      } finally {
        await bookmark.setLoading('restore', false)
      }
    },
    // comments
    async getComments(_, { bookmark, data }) {
      const { results, next } = await this.$api.bookmark.comments(bookmark.ownerId, bookmark.id, data)
      await UserComment.insertOrUpdate({ data: results })

      return { results, next }
    },
    async comment(_, { bookmark, data }) {
      const comment = await this.$api.bookmark.comment(bookmark.ownerId, bookmark.id, data)
      await UserComment.insert({ data: comment })

      return comment
    },
    async removeComment(_, { bookmark, commentId }) {
      await this.$api.bookmark.removeComment(bookmark.ownerId, bookmark.id, commentId)
      await UserComment.delete(commentId)
    },
    async setCommentsSeen(_, { bookmark }) {
      try {
        await this.$api.bookmark.setCommentsSeen(bookmark.ownerId, bookmark.id)
      } catch (error) {
        console.error(error)
      }
    },
    // misc
    async addToCollections(_, { bookmark, ...data }) {
      return this.$api.bookmark.addToCollections(bookmark.ownerId, bookmark.id, data)
    },
    async save({ rootGetters }, { bookmark, ...data }) {
      try {
        await bookmark.setLoading('save', true)

        const privateCollectionId = rootGetters['auth/collectionId']
        await this.$api.bookmark.addToCollections(bookmark.ownerId, bookmark.id, {
          collectionIds: [privateCollectionId],
          ...data,
          isPrivate: true,
        })

        return true
      } finally {
        await bookmark.setLoading('save', false)
      }
    },
    // likes
    async getLikes(_, { bookmark, data }) {
      const { results, next } = await this.$api.bookmark.likes(bookmark.ownerId, bookmark.id, data)
      await UserLike.insertOrUpdate({ data: results })
      return { results, next }
    },
    async toggleLike(_, { bookmark }) {
      try {
        await bookmark.setLoading('like', true)
        if (!bookmark.isLikedByUser) {
          const like = await this.$api.bookmark.like(bookmark.ownerId, bookmark.id)
          await UserLike.insert({ data: like })
          await Bookmark.update({
            where: bookmark.id,
            data: {
              likes: bookmark.likes + 1,
            },
          })
        } else {
          await this.$api.bookmark.removeLike(bookmark.ownerId, bookmark.id)
          await UserLike.delete(record => record.resourceId === bookmark.id)
          Bookmark.update({
            where: bookmark.id,
            data: {
              likes: bookmark.likes - 1,
            },
          })
        }
        await Bookmark.update({ where: bookmark.id, data: { isLikedByUser: !bookmark.isLikedByUser } })
      } finally {
        await bookmark.setLoading('like', false)
      }
    },
  },
}
