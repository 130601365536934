<template>
  <DropdownHybrid
    ref="dropdown"
    :sheet-title="$t('page.bookmarks.sorting.sheet')"
    :menu-props="{left: true, minWidth: 240}"
  >
    <template #activator="{ on, attrs }">
      <BrandButton
        variant="secondary"
        color="neutral25 neutral900--text"
        :class="$vnode.data.staticClass"
        v-bind="{...attrs, ...btnProps}"
        v-on="on"
      >
        <component :is="currentOption.icon" class="text-h5" />
        <span class="ml-3 d-none d-md-inline" v-text="$t(currentOption.string) " />
      </BrandButton>
    </template>

    <v-list color="neutral25" class="py-3">
      <v-list-item v-for="option in options" :key="option.key" @click="onSelect(option.value)">
        <v-list-item-icon class="mr-3">
          <component :is="option.icon" class="text-h5" />
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="neutral900--text font-weight-medium" v-text="$t(option.text)" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </DropdownHybrid>
</template>

<script>
import { mapGetters } from 'vuex'
import { BOOKMARK_SORT_FIELDS, BOOKMARK_SORT_OPTIONS_UI } from '@/utils/constants'

export default {
  inheritAttrs: false,
  props: {
    sorting: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      isCollection: 'bookmarks/isCollection',
    }),
    btnProps() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return {
          rounded: true,
          xLarge: true,
        }
      }

      return {
        height: 48,
        width: 48,
        fab: true,
      }
    },
    currentOption() {
      const direction = this.sorting[1] ? 'asc' : 'desc'
      const field = this.sorting[0]
      const key = `${field}-${direction}`
      return BOOKMARK_SORT_OPTIONS_UI[key]
    },
    options() {
      const fields = [
        {
          field: BOOKMARK_SORT_FIELDS.createdAt,
          asc: false,
        },
        {
          field: BOOKMARK_SORT_FIELDS.createdAt,
          asc: true,
        },
        {
          field: BOOKMARK_SORT_FIELDS.title,
          asc: true,
        },
        {
          field: BOOKMARK_SORT_FIELDS.title,
          asc: false,
        },
        {
          field: BOOKMARK_SORT_FIELDS.domain,
          asc: true,
        },
        {
          field: BOOKMARK_SORT_FIELDS.domain,
          asc: false,
        },
        {
          field: BOOKMARK_SORT_FIELDS.commentsNumber,
          asc: false,
        },
        {
          field: BOOKMARK_SORT_FIELDS.commentsNumber,
          asc: true,
        },
        {
          field: BOOKMARK_SORT_FIELDS.commentsDate,
          asc: false,
        },
        {
          field: BOOKMARK_SORT_FIELDS.commentsDate,
          asc: true,
        },
      ]

      if (this.isCollection) {
        fields.push(
          {
            field: BOOKMARK_SORT_FIELDS.likes,
            asc: false,
          },
          {
            field: BOOKMARK_SORT_FIELDS.likes,
            asc: true,
          },
        )
      }

      const options = fields.map(({ field, asc }) => {
        const direction = asc ? 'asc' : 'desc'
        const key = `${field}-${direction}`

        return {
          key,
          text: BOOKMARK_SORT_OPTIONS_UI[key].string,
          icon: BOOKMARK_SORT_OPTIONS_UI[key].icon,
          value: [field, asc],
        }
      })

      return options
    },
  },
  methods: {
    onSelect(val) {
      this.$refs.dropdown?.close()
      this.$emit('set', val)
    },
  },
}
</script>
