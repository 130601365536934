import Bookmark from '@/store/models/bookmarks/Bookmark'
import Collection from '@/store/models/collections/Collection'
import CollectionMember from '@/store/models/collections/CollectionMember'
import CollectionShareLink from '@/store/models/collections/CollectionShareLink'
import { stateGetters, stateSetters } from '@/utils/store/store-utils'
import {
  DEFAULT_DURATION,
  MAX_READ_TIME,
  DEACTIVATION_DURATION,
} from '@/utils/constants'
import BookmarkTag from '@/store/models/bookmarks/BookmarkTag'

const defaultState = {
  isActive: false,
  isAllSelected: false,
  tagFilters: {
    next: null,
    asc: true,
    sortBy: 'name',
    showCount: 25,
  },
}

export default {
  name: 'bulkActions',
  namespaced: true,
  state: defaultState,
  getters: {
    ...stateGetters(defaultState),
    filterPayload(_s, _g, _rs, rootGetters) {
      // INFO: must be same as the bookmarks/getBookmarks
      // FIX ME: unify
      const filters = rootGetters['bookmarks/filters']
      const isCollection = rootGetters['bookmarks/isCollection']

      const search = filters.search?.trim()
      const isFavorite = !isCollection && filters.isFavorite
      const readTime = [
        filters.readTime[0] || false,
        filters.readTime[1] >= MAX_READ_TIME ? false : filters.readTime[1],
      ]

      return {
        filters: {
          tags: filters.tags,
          ...(isFavorite && { isFavorite }),
          ...(search?.length >= 2 && { search }),
          ...(readTime.some(Boolean) && { readTime }),
          ...(filters.languages.length && { languages: filters.languages }),
        },
      }
    },
    hasActiveFilters(_s, getters) {
      const { filterPayload } = getters
      if (Object.keys(filterPayload.filters).length > 1) return true
      if (!!filterPayload.filters.tags && filterPayload.filters.tags.length > 0) return true
      return false
    },
    bookmarks() {
      return Bookmark.query().where('isSelected', true).get()
    },
    collections() {
      return Collection.query().where('isSelected', true).with('shareLink').get()
    },
    ownedCollections(_s, _g, _rs, rootGetters) {
      return Collection.query()
        .where('isSelected', true)
        .where('ownerId', rootGetters['auth/userId'])
        .get()
    },
    notOwnedCollections(_s, _g, _rs, rootGetters) {
      return Collection.query()
        .where('isSelected', true)
        .where('ownerId', id => id !== rootGetters['auth/userId'])
        .get()
    },
    numberOfCollections(s, g) {
      if (s.isAllSelected) {
        return Collection.all().length
      }
      return g.collections.length
    },
    numberOfBookmarks(s, g, _rs, rootGetters) {
      if (s.isAllSelected) {
        return rootGetters['bookmarks/filteredBookmarkCount']
      }
      return g.bookmarks.length
    },
    numberOfSelectedItems(_, g, _rs, rootGetters) {
      if (rootGetters['bookmarks/isCollection']) {
        return g.numberOfCollections
      }
      return g.numberOfBookmarks
    },
    showActions(_, getters) {
      return getters.numberOfBookmarks > 0 || getters.collections.length > 0
    },
  },
  mutations: {
    ...stateSetters(defaultState),
    add(_, bookmark) {
      bookmark.setIsSelected(true)
    },
    remove(_, bookmark) {
      bookmark.setIsSelected(false)
    },
    clear() {
      Collection.update({
        where: () => true,
        data: {
          isSelected: false,
        },
      })

      Bookmark.update({
        where: () => true,
        data: {
          isSelected: false,
        },
      })
    },

    closeActions(state) {
      Collection.update({
        where: () => true,
        data: {
          isSelected: false,
        },
      })

      Bookmark.update({
        where: () => true,
        data: {
          isSelected: false,
        },
      })

      state.isActive = false
      state.isAllSelected = false
    },
    toggleActions(state) {
      Collection.update({
        where: () => true,
        data: {
          isSelected: false,
        },
      })

      Bookmark.update({
        where: () => true,
        data: {
          isSelected: false,
        },
      })

      state.isActive = !state.isActive
      state.isAllSelected = false
    },
    setTagsNext(state, newNext) {
      state.tagFilters.next = newNext
    },
  },
  actions: {
    async delete({ getters, state, rootGetters }) {
      const bulkBookmarks = getters.bookmarks
      const bulkBookmarksIds = bulkBookmarks.map(i => i.id)
      const ownerId = rootGetters['bookmarks/collectionId']

      try {
        await Promise.all(bulkBookmarks.map(bookmark => bookmark.setLoading('remove', true)))

        await this.$api.bookmark.removeBulk(ownerId, {
          ...getters.filterPayload,
          bookmarkIds: bulkBookmarksIds,
          isAllSelected: state.isAllSelected,
        })

        if (state.isAllSelected) {
          await Bookmark.deleteAll()
        } else {
          await Bookmark.delete(record => bulkBookmarksIds.includes(record.id))
        }

        return true
      } finally {
        await Promise.all(bulkBookmarks.map(bookmark => bookmark.setLoading('remove', false)))
      }
    },
    async toggleFavorite({ getters, rootGetters, state }, { isFavorite }) {
      const bulkBookmarks = getters.bookmarks
      const bulkBookmarksIds = bulkBookmarks.map(i => i.id)
      const ownerId = rootGetters['bookmarks/collectionId']
      try {
        await Promise.all(bulkBookmarks.map(bookmark => bookmark.setLoading('favorite', true)))

        const result = await this.$api.bookmark.favoriteBulk(ownerId, {
          ...getters.filterPayload,
          bookmarkIds: bulkBookmarksIds,
          isAllSelected: state.isAllSelected,
          isFavorite,
        })

        bulkBookmarks.forEach(bookmark => {
          Bookmark.update({
            where: bookmark._id,
            data: {
              isFavorite,
            },
          })
        })

        return result
      } finally {
        await Promise.all(bulkBookmarks.map(bookmark => bookmark.setLoading('favorite', false)))
      }
    },
    async restore({ getters, rootGetters, state }) {
      const bulkBookmarks = getters.bookmarks
      const bulkBookmarksIds = bulkBookmarks.map(i => i.id)
      const ownerId = rootGetters['bookmarks/collectionId']

      try {
        await Promise.all(bulkBookmarks.map(bookmark => bookmark.setLoading('restore', true)))
        await this.$api.bookmark.restoreBulk(ownerId, {
          bookmarkIds: bulkBookmarksIds,
          isAllSelected: state.isAllSelected,
        })

        if (state.isAllSelected) {
          await Bookmark.deleteAll()
        } else {
          await Bookmark.delete(record => bulkBookmarksIds.includes(record.id))
        }

        return true
      } finally {
        await Promise.all(bulkBookmarks.map(bookmark => bookmark.setLoading('restore', false)))
      }
    },
    async fullDelete({ getters, state, rootGetters }) {
      const bulkBookmarks = getters.bookmarks
      const bulkBookmarksIds = bulkBookmarks.map(i => i.id)
      const ownerId = rootGetters['bookmarks/collectionId']

      try {
        await Promise.all(bulkBookmarks.map(bookmark => bookmark.setLoading('remove', true)))
        await this.$api.bookmark.removeFullBulk(ownerId, {
          bookmarkIds: bulkBookmarksIds,
          isAllSelected: state.isAllSelected,
        })

        if (state.isAllSelected) {
          await Bookmark.deleteAll()
        } else {
          await Bookmark.delete(record => bulkBookmarksIds.includes(record.id))
        }

        return true
      } finally {
        await Promise.all(bulkBookmarks.map(bookmark => bookmark.setLoading('remove', false)))
      }
    },
    async save({ getters, state, rootGetters }, { ...data }) {
      const bulkBookmarks = getters.bookmarks
      const bulkBookmarksIds = bulkBookmarks.map(i => i.id)
      const privateCollectionId = rootGetters['auth/collectionId']
      const ownerId = rootGetters['bookmarks/collectionId']

      try {
        await Promise.all(bulkBookmarks.map(bookmark => bookmark.setLoading('save', true)))

        await this.$api.bookmark.addToCollectionsBulk(ownerId, {
          ...getters.filterPayload,
          bookmarkIds: bulkBookmarksIds,
          collectionIds: [privateCollectionId],
          isPrivate: true,
          isAllSelected: state.isAllSelected,
          ...data,
        })

        return true
      } finally {
        await Promise.all(bulkBookmarks.map(bookmark => bookmark.setLoading('save', true)))
      }
    },
    async addToCollections({ getters, state, rootGetters }, { collectionIds, ...data }) {
      const bulkBookmarks = getters.bookmarks
      const bulkBookmarksIds = bulkBookmarks.map(i => i.id)
      const ownerId = rootGetters['bookmarks/collectionId']
      try {
        await Promise.all(bulkBookmarks.map(bookmark => bookmark.setLoading('save', true)))

        await this.$api.bookmark.addToCollectionsBulk(ownerId, {
          ...getters.filterPayload,
          bookmarkIds: bulkBookmarksIds,
          collectionIds,
          isAllSelected: state.isAllSelected,
          ...data,
        })

        return true
      } finally {
        await Promise.all(bulkBookmarks.map(bookmark => bookmark.setLoading('save', true)))
      }
    },
    async addTags({ getters, state }, { tags, ownerId }) {
      const bulkBookmarksIds = getters.bookmarks.map(i => i.id)

      const result = await this.$api.bookmark.addTagsBulk(ownerId, {
        ...getters.filterPayload,
        bookmarkIds: bulkBookmarksIds,
        isAllSelected: state.isAllSelected,
        tags,
      })

      return result
    },

    async removeTags({ getters, state }, { tags, ownerId }) {
      const bulkBookmarksIds = getters.bookmarks.map(i => i.id)

      const result = await this.$api.bookmark.removeTagsBulk(ownerId, {
        ...getters.filterPayload,
        bookmarkIds: bulkBookmarksIds,
        isAllSelected: state.isAllSelected,
        tags,
      })

      return result
    },

    async deleteCollections({ state }, { collections }) {
      await this.$api.collection.removeBulk({
        collectionIds: collections.map(i => i.id),
        isAllSelected: state.isAllSelected,
      })

      const collectionIdsToDelete = collections.map(c => c.id)
      await Collection.delete(i => collectionIdsToDelete.includes(i.collectionId))
      await CollectionMember.delete(i => collectionIdsToDelete.includes(i.collectionId))
      await Bookmark.delete(i => collectionIdsToDelete.includes(i.ownerId))

      return true
    },

    async removeMember({ state }, { collections, memberIds }) {
      await this.$api.collection.removeMemberBulk({
        collectionIds: collections.map(i => i.id),
        memberIds,
        isAllSelected: state.isAllSelected,
      })

      await CollectionMember.delete(i => memberIds.includes(i.id))
    },
    async inviteEmails({ getters, state }, { emails }) {
      const bulkCollections = getters.collections
      const collectionIds = bulkCollections.map(i => i.id)
      return this.$api.collection.inviteEmailsBulk({
        collectionIds,
        invites: emails,
        isAllSelected: state.isAllSelected,
      })
    },

    async editShareLinks(_, { tokenMap, duration }) {
      const result = await this.$api.collection.editShareLinkBulk({
        duration,
        links: tokenMap,
      })

      const shareLinkIds = Object.values(tokenMap)

      await Promise.all(shareLinkIds.map(shareLinkId => CollectionShareLink.update({
        where: shareLinkId,
        data: result,
      })))

      return result
    },

    async getShareLinks({ state }, collections) {
      const linksMap = await this.$api.collection.shareLinksBulk({
        collectionIds: collections.map(i => i.id),
        isAllSelected: state.isAllSelected,
      })

      const shareLinks = Object.values(linksMap).map(link => ({
        ...link,
        collectionId: link.of,
      }))

      await CollectionShareLink.insertOrUpdate({
        data: shareLinks,
      })

      return linksMap
    },

    async createShareLinks({ state }, collections) {
      const linkMap = await this.$api.collection.createShareLinkBulk({
        duration: DEFAULT_DURATION,
        collectionIds: collections.map(i => i.id),
        isAllSelected: state.isAllSelected,
      })
      return linkMap
    },

    async deactivateShareLinks(_, { tokenMap }) {
      const result = await this.$api.collection.editShareLinkBulk({
        duration: DEACTIVATION_DURATION,
        links: tokenMap,
      })

      const shareLinkIds = Object.values(tokenMap)

      await Promise.all(shareLinkIds.map(shareLinkId => CollectionShareLink.update({
        where: shareLinkId,
        data: result,
      })))

      return result
    },

    async deleteShareLinks(_, { tokenMap }) {
      await this.$api.collection.deleteShareLinkBulk({
        links: tokenMap,
      })
      const shareLinkIds = Object.values(tokenMap)
      await CollectionShareLink.delete(i => shareLinkIds.includes(i.id))
    },

    async getTags({
      getters, state, commit,
    }, ownerId) {
      const bulkBookmarks = getters.bookmarks
      const bulkBookmarksIds = bulkBookmarks.map(i => i.id)
      // const ownerId = rootGetters['bookmarks/collectionId']

      const { results, next } = await this.$api.bookmark.getBulkTags(ownerId, {
        ...getters.filterPayload,
        bookmarkIds: bulkBookmarksIds,
        isAllSelected: state.isAllSelected,
        next: state.tagFilters.next,
        limit: state.tagFilters.showCount,
        sortBy: state.tagFilters.sortBy,
        asc: state.tagFilters.asc,
      })

      await commit('setTagsNext', next)

      await BookmarkTag.insertOrUpdate({ data: results })

      return { results, next }
    },
  },
}
