import { Database, Model } from '@vuex-orm/core'

const database = new Database()

const req = require.context('@/store/models', true, /[A-Z]\w+\.(js)$/)

req.keys().forEach(fileName => {
  const model = req(fileName)

  if (model.default && model.default.prototype instanceof Model) {
    database.register(model.default, model.store)
  }
})

export default database
