import { mapActions, mapGetters, mapMutations } from 'vuex'
import { PhFadersHorizontal } from 'phosphor-vue'

import Bookmark, { SHOW_COUNT } from '@/store/models/bookmarks/Bookmark'

import PullToRefreshMixin from '@/mixins/PullToRefresh'
import { debounce, getLocationQuery } from '@/utils/helpers'

import SearchButtonInput from '@/components/forms/fields/SearchButtonInput'
import qs from 'qs'
import { MAX_READ_TIME } from '@/utils/constants'
import BookmarksSortingSwitcher from '../components/BookmarksSortingSwitcher.vue'
import BookmarksStyleSwitcher from '../components/BookmarksStyleSwitcher.vue'
import BookmarkList from '../components/BookmarkList.vue'
import BookmarkFilterIndicator from '../components/BookmarkFilterIndicator.vue'
import BookmarkImportIndicator from '../components/BookmarkImportIndicator.vue'
import BookmarkSelectSwitcher from '../components/BookmarkSelectSwitcher.vue'
import BookmarkFilterSwitcher from '../components/BookmarkFilterSwitcher.vue'
import BookmarkBulkActions from '../components/BookmarkBulkActions.vue'

// This mixin requires the implementing component to provide all bookmark related store bindings

export default {
  components: {
    PhFadersHorizontal,
    BookmarkFilterIndicator,
    BookmarkImportIndicator,
    BookmarkList,
    BookmarksStyleSwitcher,
    BookmarksSortingSwitcher,
    SearchButtonInput,
    BookmarkSelectSwitcher,
    BookmarkBulkActions,
    BookmarkFilterSwitcher,
  },
  mixins: [PullToRefreshMixin],
  data() {
    return {
      loading: {
        bookmarks: false,
        stats: false,
        tags: false,
      },
      searchFocused: false,
    }
  },
  computed: {
    ...mapGetters({
      userId: 'auth/userId',
      listStyle: 'bookmarks/listStyle',
      isExtensionPopup: 'device/isExtensionPopup',
      isDesktop: 'device/isDesktop',
      isMobile: 'device/isMobile',
      //
      show: 'bookmarks/show',
      next: 'bookmarks/next',
      filters: 'bookmarks/filters',
      defaultFilterFn: 'bookmarks/defaultFilterFn',
      sorting: 'bookmarks/sorting',
      collectionListStyle: 'bookmarks/collectionListStyle',
      bookmarkCount: 'bookmarks/bookmarkCount',
      importQueueSize: 'bookmarks/importQueueSize',
      sumImportQueueSize: 'bookmarks/sumImportQueueSize',
      collectionId: 'bookmarks/collectionId',
      filteredBookmarkCount: 'bookmarks/filteredBookmarkCount',
      // FIX ME: move it to bookmarks module
      hasActiveFilters: 'bulkActions/hasActiveFilters',
    }),
    bookmarks() {
      const { filters, show } = this
      // const sortingDirection = filters.sorting[1] ? 'asc' : 'desc'

      const query = Bookmark.query()
        .where('ownerId', this.collectionId)
        .with(['tags'])
        .limit(show)

      // NOTE Do we really need to handle filters here?
      // Bookmarks are cleared fully from memory each time filters change.

      if (filters.isFavorite) {
        query.where('isFavorite', true)
      }

      if (filters.tags.length) {
        query.where('tagIds', tagIds => filters.tags.some(filterTag => tagIds.includes(filterTag)))
      }

      if (filters.search) {
        // search turns off any other sorting
        query.orderBy('searchScore', 'desc')
      } else {
        // INFO: messes with the backend sorting which should be correct
        // query.orderBy(filters.sorting[0], sortingDirection)
      }

      return query.get()
    },
    filtersActive() {
      const { filters, defaultFilterFn } = this
      const defaults = defaultFilterFn()

      const changes = []
      if (filters.search.length) changes.push('search')
      if (filters.tags.length) changes.push('tags')
      if (filters.isFavorite) changes.push('favorites')
      if (filters.languages.length) changes.push('languages')
      if (JSON.stringify(filters.readTime) !== JSON.stringify(defaults.readTime)) changes.push('languages')

      if (!changes.length) return false
      return changes
    },
  },
  watch: {
    filters: {
      handler(val, old) {
        if (JSON.stringify(val) === JSON.stringify(old)) return
        Bookmark.delete(i => i.ownerId === this.collectionId)
        this.resetPagination()
        this.loadBookmarks(true)

        this.setUrlFromFilters()
      },
    },
  },
  async created() {
    const routeCollectionId = this.$route.params.id ?? null
    this.setCollectionId(routeCollectionId)

    this.resetPagination()
    this.setFiltersFromUrl()

    // delete potentially stale results
    Bookmark.delete(i => i.ownerId === this.collectionId)

    // load initial results
    Promise.all([
      this.onGetStats(),
      this.onGetPopularTags(),
      this.loadBookmarks(),
    ]).catch(console.error)
  },
  mounted() {
    if (this.isMobile) {
      this.ptrInit()
    }
  },
  beforeDestroy() {
    this.setCollectionId(null)
  },
  methods: {
    ...mapActions({
      getBookmarks: 'bookmarks/getBookmarks',
      getBookmarkStats: 'bookmarks/getStats',
      getPopularTags: 'bookmarks/getPopularTags',
      setFilters: 'bookmarks/setFilters',
      setSorting: 'bookmarks/setSorting',
      setFilterTags: 'bookmarks/setFilterTags',
    }),
    ...mapMutations({
      setCollectionId: 'bookmarks/setCollectionId',
      setListStyle: 'bookmarks/setListStyle',
      reset: 'bookmarks/reset',
      setShow: 'bookmarks/setShow',
      setNext: 'bookmarks/setNext',
      setCollectionListStyle: 'bookmarks/setCollectionListStyle',
      setFilterSidebarOpen: 'bookmarks/setFilterOpen',
      setFilteredBookmarkCount: 'bookmarks/setFilteredBookmarkCount',
    }),
    async refreshBookmarks() {
      Promise.all([
        this.onGetStats(),
        this.resetPagination(),
        this.loadBookmarks(true),
      ]).catch(console.error)
    },
    async loadBookmarks(forced = false) {
      if (this.loading.bookmarks && !forced) return
      this.loading.bookmarks = true

      try {
        const { results, next, bookmarkCount } = await this.getBookmarks()

        this.setNext(next)
        this.setFilteredBookmarkCount(bookmarkCount)
        this.setShow(this.show + results.length)
      } catch (error) {
        this.$error.toast(error)
      } finally {
        this.loading.bookmarks = false
      }
    },
    // stats
    async onGetStats() {
      if (this.loading.stats) return
      this.loading.stats = true

      try {
        await this.getBookmarkStats()
      } catch (error) {
        this.$error.toast(error)
      } finally {
        this.loading.stats = false
      }
    },
    async onGetPopularTags() {
      if (this.loading.tags) return
      this.loading.tags = true

      try {
        await this.getPopularTags()
      } catch (error) {
        this.$error.toast(error)
      } finally {
        this.loading.tags = false
      }
    },
    // filter logic
    setFiltersFromUrl() {
      const search = getLocationQuery()
      if (!search) {
        this.resetFilters()
        return
      }

      const defaults = this.defaultFilterFn()
      const query = qs.parse(search, { ignoreQueryPrefix: true, comma: true, parseBooleans: true })

      // avoid 'true'
      if (defaults.isFavorite !== undefined && query.isFavorite) {
        query.isFavorite = true
      }

      // avoid broken tags
      if (query.tags && !Array.isArray(query.tags)) {
        delete query.tags
      }

      // avoid broken read time
      if (!Array.isArray(query.readTime) || query.readTime.length !== 2 || query.readTime[1] > MAX_READ_TIME) {
        delete query.readTime
      }

      // avoid broken languages
      if (!Array.isArray(query.language)) {
        delete query.language
      }

      // avoid broken sorting
      if (!Array.isArray(query.sorting) || query.sorting.length !== 2) {
        delete query.sorting
      }

      if (Array.isArray(query.sorting)) {
        query.sorting[1] = query.sorting[1] === 'true'
      }
      this.setFilters({
        ...this.filters,
        ...query,
      })

      this.$nextTick(() => this.$bus.$emit('bookmarks:filters:set-from-url'))
    },
    setUrlFromFilters() {
      const defaults = this.defaultFilterFn()
      const query = {}

      // filter out defaults
      for (const [k, v] of Object.entries(this.filters)) {
        if (JSON.stringify(defaults[k]) !== JSON.stringify(v)) {
          query[k] = v
        }
      }

      const querystring = qs.stringify(query, { arrayFormat: 'comma', commaRoundTrip: true })
      const routerQuery = Object.fromEntries(new URLSearchParams(querystring).entries())

      this.$router.replace({ query: routerQuery }).catch(() => {})
    },
    onOpenFilter() {
      this.setFilterSidebarOpen(true)
    },
    onSearchInput: debounce(function fn(search) {
      this.setFilters({ ...this.filters, search })
    }, 500),
    resetFilters() {
      this.setFilters(this.defaultFilterFn())
    },
    resetPagination() {
      this.setNext(null)
      this.setShow(SHOW_COUNT)
    },
    //
    ptrOnRefresh() {
      Bookmark.delete(i => i.ownerId === this.collectionId)
      this.resetPagination()
      this.loadBookmarks()
    },
  },
}
