import Vue from 'vue'

const req = require.context('@/components/global', true, /[A-z0-9_-]+\.(vue)$/)

req.keys().forEach(fileName => {
  const component = req(fileName)

  // Get name of component, assumes PascalCase
  const componentName = fileName.split('/').pop().replace(/\.\w+$/, '')

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    component.default || component,
  )
})
