import { stateGetters, stateSetters } from '@/utils/store/store-utils'
import EventBus from '@/plugins/eventbus'

function isValidDate(dateString) {
  const date = new Date(dateString)
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(date.getTime())
}

const defaultState = {
  UnderMaintenance: false,
  AppUpdatedAt: new Date(),
}

export default {
  name: 'features',
  namespaced: true,
  state: () => defaultState,
  getters: {
    ...stateGetters(defaultState),
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    async fetchFeatures({ commit }) {
      const { features } = await this.$api.features.fetchFeatures()
      commit('setUnderMaintenance', features.UnderMaintenance)

      if (features.AppUpdatedAt && isValidDate(features.AppUpdatedAt)) {
        commit('setAppUpdatedAt', new Date(features.AppUpdatedAt))
      }
    },
  },
}

EventBus.$once('app:created', async vm => {
  vm.$store.dispatch('features/fetchFeatures').catch(console.error)
})
