// Folders in this directory automatically
// get their index.js mounted into vuex as a module

const req = require.context('./', true, /index.js$/)
const modules = {}

req.keys().forEach(fileName => {
  if (fileName === './index.js') return

  const module = req(fileName)
  const moduleName = module.default?.name ?? fileName.split('/')[1].replace(/-_. /g, '').toLowerCase()

  modules[moduleName] = module.default || module
})

export default modules
