<template>
  <v-snackbar
    v-bind="$attrs"
    :value="value"
    app rounded="0" :timeout="-1"
    class="cursor-pointer active-import-notice"
    content-class="full-width d-flex align-center pa-0"
  >
    <div class="snackbar-content full-width">
      <v-container class="max-app-width pa-4">
        <v-row dense align="center">
          <v-col cols="auto" class="d-flex align-center">
            <span>{{ $tc('component.bookmarks.import.in_progress') }}</span>
          </v-col>
        </v-row>
      </v-container>
      <v-progress-linear class="full-width prgress-bar" indeterminate />
    </div>
  </v-snackbar>
</template>

<script>

export default {
  components: { },
  inheritAttrs: false,
  props: {
    value: {
      type: [Boolean, Array],
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.active-import-notice :deep() {
  .v-snack__wrapper {
    margin: 0;
  }

  .v-snack__action {
    display: none;
  }
}
.snackbar-content {
  position: relative;
}
.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
}
</style>
